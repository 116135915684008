<template>
  <b-link
    class="brand-logo"
    @click="goHome()"
  >
    <img
      :src="appLogoImage"
      alt="Logo"
    >
    <h2 class="brand-text text-primary ml-1">
      {{ appName }}
    </h2>
  </b-link>
</template>

<script>
/* eslint-disable global-require */
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import router from '@/router'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      appName: $themeConfig.app.appName,
      appLogoImage: $themeConfig.app.appLogoImage,
    }
  },
  computed: {
    goHome() {
      return router.push('/')
    },
  },
}
</script>
